



























































.avatar-wrapper {
  &--controls {
    opacity: 0;
    z-index: -1;
    transition: all 0.2s ease-in;
    flex-direction: column;
  }

  &--avatar {
    transition: all 0.3s ease-in-out;
  }

  &:focus,
  &:hover {
    outline: none;

    .avatar-wrapper--controls {
      opacity: 1;
      z-index: 1;
    }

    .avatar-wrapper--avatar {
      opacity: 0.3;
    }
  }
}

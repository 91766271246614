
























.auth-layout {
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.auth-layout--card {
  width: 812px;
}

@media screen and (max-width: 1923px) {
  .auth-layout {
    background-image: url('../assets/images/auth-bg.jpg');
  }
}

@media screen and (min-width: 1924px) {
  .auth-layout {
    background-image: url('../assets/images/auth-bg@2x.jpg');
  }
}

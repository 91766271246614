












































@import "~quasar-variables";

body:not(.body--dark) {
  .main-layout {
    &--page-container {
      background: #fafafa;
    }
  }
}














.errorLayout {
  min-height: 100vh;
  background-image: url('../assets/images/obashi-auth-bg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-top: 100px;
}

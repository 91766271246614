


























































































.photo-editor-container {
  min-width: 600px;
}

.photo-editor-loader {
  min-width: 300px;
  height: 100%;
}

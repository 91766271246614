













































































@import "~quasar-variables";

.breadcrumbs {
  a {
    font-weight: bold;
  }

  span {
    font-style: italic;
    opacity: 0.8;
  }
}

.text-quote {
  quotes: "“" "”" "‘" "’";
  opacity: 0.6;
  font-style: italic;

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}


















































































.page-tabs {
  .q-tabs--vertical .q-tab__indicator {
    width: 8px;
  }

  .q-tab__content {
    width: 100%;
    align-items: end;
    padding-right: 16px;
  }

  .q-tab__label {
    font-size: 1rem;
    font-weight: normal;
  }
}









































@import '~quasar-variables';

body {
  footer {
    font-size: 0.79rem;
  }

  &.body--dark {
    footer {
      background-color: $dark;

      a,
      .copy {
        color: $light-grey;
      }
    }
  }

  &:not(.body--dark) {
    footer {
      background: $blue-grey-2;

      a,
      .copy {
        color: $dark-grey;
      }
    }
  }
}

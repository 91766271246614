kbd {
  margin: 0 0.1em;
  padding: 0.2em 0.6em;
  border-radius: 3px;
  border: 1px solid rgb(200 200 200);
  color: rgb(51 51 51);
  line-height: 1.4;
  font-family: monospace;
  font-size: 1rem;
  letter-spacing: 1px;
  display: inline-block;
  box-shadow: 0 1px 0 rgb(0 0 0 / 20%), inset 0 0 0 2px #fff;
  background-color: rgb(240 240 240);
  text-shadow: 0 1px 0 #fff;
}

$primary: #474747;
$secondary: #e3e3e3;
$accent: #fb8241;

$positive: #167c67;
$negative: #d42626;
$info: #219ccc;
$warning: #f1ac30;

$dark-grey: #29292b;
$light-grey: #eaedee;
$border-color: $light-grey;

$dark-page: #121212;
$dark: #1d1d1d;

$primary-gradient: linear-gradient(90deg, #f80000, #fb8241);
$primary-gradient-hover: linear-gradient(90deg, #e3094a, #e2753b);
$primary-gradient-reverse: linear-gradient(90deg, #fb8241, #fc0a52);

$secondary-gradient: linear-gradient(90deg, #167c67, #0db0ec);
$secondary-gradient-hover: linear-gradient(90deg, #0c9ed4, #0d4a3e);

$accent-gradient: linear-gradient(90deg, #f02cbe, #ff7077);
$accent-gradient-hover: linear-gradient(90deg, #d828ab, #e6656b);

@import "../colors/brand";

.btn-primary,
.btn-secondary,
.btn-accent {
  height: 50px;
  color: #fff;
  border-radius: 8px;
}

.btn-primary {
  background: $primary-gradient;

  &:hover {
    background: $primary-gradient-hover;
  }
}

.btn-secondary {
  background: $secondary-gradient;

  &:hover {
    background: $secondary-gradient-hover;
  }
}

.btn-accent {
  background: $accent-gradient;

  &:hover {
    background: $accent-gradient-hover;
  }
}

@import "~quasar-variables";

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.drop-area {
  background-color: $blue-1;
  border: 1px dashed $blue-3;
  border-radius: $generic-border-radius;
  text-align: center;
  position: absolute;
  top: -1px;
  left: 7px;
  right: 7px;
  height: 50px;
}

// Reset quasar default field error style.
.q-field--with-bottom {
  padding-bottom: 0;
}

.q-field__bottom--animated {
  position: relative;
  transition: all 0.1s ease-in-out;
  transform: scaleY(0) translateY(0);
}

.q-field--error {
  .q-field__bottom--animated {
    transform: scaleY(1);
  }

  &.q-field--with-bottom {
    padding-bottom: 15px;
  }
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

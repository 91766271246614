







































































@import '~quasar-variables';

.app-card {
  border-radius: $generic-border-radius;
  // display: grid;
  // grid-template-columns: 1fr;
  // gap: 0 0;
  // grid-template-areas:
  //  "header"
  //  "body"
  //  "footer";

  &--header {
    // grid-area: header;
    // height: 50px;
  }
  // &--body { grid-area: body; }
  // &--footer { grid-area: footer; }

  &--header,
  &--footer {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .sticky-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

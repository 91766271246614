.card-shadow {
  box-shadow:
    2.2px 2px 0 -2px rgb(0 0 0 / 4.7%),
    3.1px 2.8px 0.8px -2px rgb(0 0 0 / 5.8%),
    2.9px 2.6px 2.6px -2px rgb(0 0 0 / 6.4%),
    2.5px 2.2px 5.8px -2px rgb(0 0 0 / 6.7%),
    4.4px 4px 11.4px -2px rgb(0 0 0 / 6.9%),
    12px 11px 25px -2px rgb(0 0 0 / 7%);
}

.light-shadow {
  box-shadow: 2px 3px 4px rgb(0 0 0 / 16%);
}

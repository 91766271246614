























































































































































































































































@import '~quasar-variables';

.canvas {
  width: 300px;
  height: 300px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.toolbar {
  background-color: $dark;
  border-radius: 3px;
  color: #fff;
}

.toolbar-right {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
    background: white;
    width: 1px;
    opacity: 0.5;
  }
}











































.main-toolbar {
  .main-toolbar-logo {
    max-width: 100px;
    height: auto;
  }
}

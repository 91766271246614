@import '~rfs/scss';

// Body general style
$font-family: 'DIN Next LT Pro', '-apple-system', 'Helvetica Neue', 'Helvetica',
  'Arial', sans-serif;

* {
  font-family: $font-family;
}

body {
  font-size: 100%; /* 16px */
  letter-spacing: (-40 / 1000) * 1rem;
}

.spaced-letters {
  letter-spacing: (89 / 1000) * 1rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 2.75rem 0 1.05rem;
  line-height: 1.15;
}

// 48.83 px
h1,
.text-h1 {
  margin-top: 0;

  @include rfs(3.052rem);
}

// 39.06 px
h2,
.text-h2 {
  @include rfs(2.441rem);
}

// 32.25 px
h3,
.text-h3 {
  @include rfs(1.953rem);
}

// 25 px
h4,
.text-h4 {
  @include rfs(1.563rem);
}

// 20 px
h5,
.text-h5 {
  @include rfs(1.25rem);
}

// 16 px
h6,
.text-h6 {
  font-weight: 500;

  @include rfs(1rem);
}

// 16 px
p {
  margin-bottom: 1.15rem;

  @include rfs(1rem);
}

small,
.text_small {
  @include rfs(0.8rem);
}

.text-subtitle1 {
  line-height: 1.3rem;
}

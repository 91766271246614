@import 'social-colors';

$colors: (
  'facebook': $facebook,
  'twitter': $twitter,
  'linkedin': $linkedin,
  'instagram': $instagram,
);

@each $color-name, $color in $colors {
  @if $color-name == 'instagram' {
    .text-#{$color-name} {
      background: $color;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .bg-#{$color-name} {
      background-color: $color;
    }
  }

  @else {
    .text-#{$color-name} {
      color: $color;
    }

    .bg-#{$color-name} {
      background-color: $color;
    }
  }
}

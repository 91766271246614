/**
* IMPORTANT - do not add any code that compiles to CSS
 */

@import 'colors/brand';

$button-border-radius: 8px;
$generic-border-radius: 8px;
$shadow-color: #777;

// quasar variables
@import '~quasar/src/css/variables';

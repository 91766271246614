@import "~quasar-variables";

.bordered {
  border: 1px solid $border-color;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

.border-after {
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    content: "";
    border: 1px solid $border-color;
  }
}

.border-before {
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    border: 1px solid $border-color;
  }
}

@import 'obashi-colors';

$colors: (
  'layer-0': ('color': $color-ownership, 'accent': $color-ownership-dark),
  'layer-1': ('color': $color-business, 'accent': $color-business-dark),
  'layer-2': ('color': $color-application, 'accent': $color-application-dark),
  'layer-3': ('color': $color-system, 'accent': $color-system-dark),
  'layer-4': ('color': $color-hardware, 'accent': $color-hardware-dark),
  'layer-5': ('color': $color-infrastructure, 'accent': $color-infrastructure-dark),
);

@each $color-name, $color-map in $colors {
  .text-#{$color-name} {
    color: map-get($color-map, 'color');
  }

  .text-accent-#{$color-name} {
    color: map-get($color-map, 'accent');
  }

  .bg-#{$color-name} {
    background-color: map-get($color-map, 'color');
  }

  .bg-accent-#{$color-name} {
    background-color: map-get($color-map, 'accent');
  }
}


































































@import "~quasar-variables";

.photo-editor-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px dashed $grey-6;
  transition: all 0.2s ease-in-out;

  p {
    transition: all 0.2s ease-in-out;
  }

  &.active {
    border-color: $light-blue-5;
    background-color: $light-blue-1;

    p {
      opacity: 0;
    }
  }
}

@import "../colors/brand";

html {
  color: $dark-grey;
}

/* primary */
.text-primary-gradient {
  color: $primary-gradient;
}

.bg-primary-gradient {
  background: $primary-gradient;
}

/* secondary */
.text-secondary-gradient {
  color: $secondary-gradient;
}

.bg-secondary-gradient {
  background: $secondary-gradient;
}

/* dark */
.text-dark {
  color: $dark;
}

.bg-dark {
  background: $dark;
}

/* light-grey */
.text-light-grey {
  color: $light-grey;
}

.bg-light-grey {
  background: $light-grey;
}

/* dark-grey */
.text-dark-grey {
  color: $dark-grey;
}

.bg-dark-grey {
  background: $dark-grey;
}
